import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ICrudProps } from "../../../interfaces/ICrud";
const CrudTasks = ({ label, value }: ICrudProps) => {
  const [content, setContent] = useState("");
  const [errContent, setErrContent] = useState(false);
  const [helperContent, setHelperContent] = useState("");
  const [resultMsg, setResultMsg] = useState("");
  const [resultSuccess, setResultSuccess] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  const toAdd: boolean = label === "Ajouter";
  const displayAlert = resultMsg === "" ? "none" : "";
  const severityAlert = resultSuccess ? "success" : "error";
  const clearCRUD = () => {
    setContent("");
  };
  const verifyData = () => {
    if (content === "") {
      setErrContent(true);
      setHelperContent("Le contenu est obligatoire.");
      return false;
    } else {
      setErrContent(false);
      setHelperContent("");
      return true;
    }
  };
  const updateTask = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        id: value,
        content: content.trim(),
      };
      Axios.post("https://cogitest.herokuapp.com/task/update", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(response.data.problem == false);
        }
      );
      clearCRUD();
    }
  };
  const addTask = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        content: content.trim(),
      };
      Axios.post("https://cogitest.herokuapp.com/task/add", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(response.data.problem == false);
        }
      );
      clearCRUD();
    }
  };

  function findTask(id: any) {
    Axios.get(`https://cogitest.herokuapp.com/task/${id}`).then(
      (response: any) => {
        if (response.data.found) {
          const task = response.data.task;
          setContent(task.contentTask);
        } else {
          alert(response.data.message);
        }
      }
    );
  }

  useEffect(() => {
    if (!firstRender && !toAdd) {
      findTask(value);
      setFirstRender(true);
    }
  }, [firstRender]);
  return (
    <div className="crudContainer">
      <Stack sx={{ width: "100%", display: displayAlert }} spacing={2}>
        <Alert severity={severityAlert}>{resultMsg}</Alert>
      </Stack>
      <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
        <TextField
          className="item"
          id="tf outlined-error-helper-text"
          label="Contenu"
          onChange={(e: any) => {
            setContent(e.target.value);
          }}
          value={content}
          error={errContent}
          helperText={helperContent}
        />
        <Button onClick={toAdd ? addTask : updateTask}>{label}</Button>
      </FormControl>
    </div>
  );
};

export default CrudTasks;
