import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Button from "@mui/material/Button";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";

import { useNavigate } from "react-router-dom";
import "./Navbar.scss";
import MUIDialog from "../Dialog/MUIDialog";
import CrudSettings from "../Crud_settings/CrudSettings";

const Navbar = () => {
  const navigate = useNavigate();
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const [name, setName] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    Axios.get("https://cogitest.herokuapp.com/authenticate", {
      headers: {
        authorization: String("Bearer " + localStorage.getItem("token")),
      },
    }).then((response: any) => {
      setCurrentUser(response.data.user);
      setName(response.data.user.firstNameUser);
    });
  });
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const settings = () => {
    setOpen(true);
  };
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="holder"></div>
        <div className="items">
          <div className="item">
            <PersonOutlineIcon className="icon" />
            Bienvenue, {name}
          </div>
          <div className="item logOut">
            <Button onClick={settings}>
              <SettingsIcon />
            </Button>
            <Button onClick={logout}>
              <LogoutIcon />
            </Button>
          </div>
          <div className="item transparent"></div>
        </div>
      </div>
      <MUIDialog
        open={open}
        handleClose={handleClose}
        onClick={settings}
        buttonContent={"Modifier"}
        titleContent={"Paramètres"}
      >
        <CrudSettings value={currentUser} />
      </MUIDialog>
    </div>
  );
};

export default Navbar;
