import { GridColDef } from "@mui/x-data-grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import AdminPageContainer from "../../../components/AdminPanel/AdminPageContainer/AdminPageContainer";
import CrudTasks from "../../../components/AdminPanel/Crud_tasks/CrudTasks";
import MUIDialog from "../../../components/AdminPanel/Dialog/MUIDialog";
import Navbar from "../../../components/AdminPanel/NavBar/Navbar";
import "./Tasks.scss";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "contentTask", headerName: "Contenu", flex: 6 },
];

const Tasks = () => {
  const [openModify, setOpenModify] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [chosenModifyTask, setChosenModifyTask] = useState(-1);
  const [chosenDeleteTask, setChosenDeleteTask] = useState(-1);
  const [rows, setRows] = useState([]);

  const handleOpenModify = (e: any) => {
    setChosenModifyTask(e.target.value);
    setOpenModify(true);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };
  const handleOpenDelete = (e: any) => {
    setChosenDeleteTask(e.target.value);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  function handleDelete() {
    const data = {
      idTask: chosenDeleteTask,
    };
    Axios.post("https://cogitest.herokuapp.com/task/delete", data).then(
      (response: any) => {
        if (response.data.deleted == false) {
          console.log(response.data.message);
        } else {
          handleCloseDelete();
        }
      }
    );
  }
  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: (params: any) => {
        const id = params.row.id;
        return (
          <div className="cellAction">
            <button
              value={id}
              className="modifyButton"
              onClick={handleOpenModify}
            >
              Modifier
            </button>
            <button
              className="deleteButton"
              value={id}
              onClick={handleOpenDelete}
            >
              Supprimer
            </button>
          </div>
        );
      },
    },
  ];
  const refreshData = () => {
    Axios.get("https://cogitest.herokuapp.com/task/list").then(
      (response: any) => {
        setRows(response.data);
      }
    );
  };
  useEffect(() => {
    if (!firstRender) {
      refreshData();
      setFirstRender(true);
    }
  }, [firstRender]);
  return (
    <>
      <Navbar />
      <AdminPageContainer
        label="tâches"
        rows={rows}
        cols={columns.concat(actionColumn)}
        refreshRows={refreshData}
      >
        <CrudTasks label="Ajouter" />
      </AdminPageContainer>
      {/** Click Modifier */}
      <MUIDialog
        open={openModify}
        handleClose={handleCloseModify}
        buttonContent={"Modifier"}
        titleContent={"Modification"}
      >
        <CrudTasks label="Modifier" value={chosenModifyTask} />
      </MUIDialog>
      {/** Click supprimer */}
      <MUIDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        buttonContent={"Supprimer"}
        titleContent={"Suppression"}
        value={chosenDeleteTask}
        onClick={handleDelete}
        content={"Est-ce que vous êtes sûr de supprmier cette tâche?"}
      ></MUIDialog>
    </>
  );
};
export default Tasks;
