export class RISQUES{

    static ASSURE=class{
        static readonly Assurance_maladie_maternité=0.028;
        static readonly Majoration_prestations=0.0025;
        static readonly Assurance_pension=0.08;
        static readonly Assurance_dependance=0.014;
    }
    static EMPLOYEUR=class{
        static readonly Assurance_maladie_maternité=0.028;
        static readonly Majoration_prestations=0.0025;
        static readonly Assurance_pension=0.08;
        static readonly Prestations_familiales=0.017;
        static readonly Sante_travail=0.0014;
        // Assurance accident
        static readonly Assurance_accident=0.0075;
        // Mutualité des employeurs
        static readonly Mutualité=0.006;
    }
}