import { Alert, Button, FormControl, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import ComboBox from "../../MU_Select/MU_Select";
import Password from "../Password/Password";
import Axios from "axios";

const data = [
  { label: "Admin", value: 1 },
  { label: "Utilisateur", value: 0 },
];
interface Props {
  value?: any;
}
const CrudSettings = ({ value }: Props) => {
  const [selectedAccess, setSelectedAccess] = useState(value.accessAdmin);
  const [firstName, setFirstName] = useState(value.firstNameUser);
  const [lastName, setLastName] = useState(value.lastNameUser);
  const [email, setEmail] = useState(value.mailUser);
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  //
  const [errFirstName, setErrFirstName] = useState(false);
  const [helperFirstName, setHelperFirstName] = useState("");
  //
  const [errLastName, setErrLastName] = useState(false);
  const [helperLastName, setHelperLastName] = useState("");
  //
  const [errEmail, setErrEmail] = useState(false);
  const [helperEmail, setHelperEmail] = useState("");
  //
  const [errPassword, setErrPassword] = useState(false);
  const [helperPassword, setHelperPassword] = useState("");
  //
  const [errConfPassword, setErrConfPassword] = useState(false);
  const [helperConfPassword, setHelperConfPassword] = useState("");
  //
  const [resultSuccess, setResultSuccess] = useState(false);
  const [resultMsg, setResultMsg] = useState("");
  //
  const [modifiedPassword, setModifiedPassword] = useState(false);
  const verifyData = () => {
    const regexAlphabet = new RegExp("^[A-Za-z\\s-]+$");
    const regexEmail = new RegExp(
      '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@(([^<>()[\\]\\.,;:\\s@"]+\\.)+[^<>()[\\]\\.,;:\\s@"]{2,})$'
    );
    const regexPassword = new RegExp(
      "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))"
    );
    const verifyFirstName: boolean = regexAlphabet.test(firstName);
    const verifyLastName = regexAlphabet.test(lastName);
    const verifyEmail = regexEmail.test(email);

    if (!verifyFirstName) {
      setErrFirstName(true);
      setHelperFirstName("Le prénom doit être alphabétique.");
    } else {
      setErrFirstName(false);
      setHelperFirstName("");
    }
    if (!verifyLastName) {
      setErrLastName(true);
      setHelperLastName("Le nom doit être alphabétique.");
    } else {
      setErrLastName(false);
      setHelperLastName("");
    }
    if (!verifyEmail) {
      setErrEmail(true);
      setHelperEmail("Vérifiez la format de l'e-mail.");
    } else {
      setErrEmail(false);
      setHelperEmail("");
    }
    var verifyPassword = regexPassword.test(password);
    var verifyConfPassword = password === confPassword;
    if (password !== "" && confPassword !== "") {
      if (!verifyPassword) {
        setErrPassword(true);
        alert(
          "Mot de passe : 6 caractères minimum, au moins une lettre majuscule, au moins une lettre minuscule et un chiffre."
        );
      } else {
        setErrPassword(false);
        setHelperPassword("");
        if (!verifyConfPassword) {
          setErrConfPassword(true);
          alert(
            "Le champ de confirmation de mot de passe n'est pas compatible."
          );
        } else {
          setErrConfPassword(false);
          setHelperConfPassword("");
        }
      }
      setModifiedPassword(true);
    } else {
      verifyPassword = true;
      verifyConfPassword = true;
      setModifiedPassword(false);
    }

    const allTrue =
      verifyFirstName &&
      verifyLastName &&
      verifyEmail &&
      verifyPassword &&
      verifyConfPassword;
    return allTrue;
  };
  const updateUser = () => {
    let allTrue = verifyData();
    let pwdValue = confPassword != "" ? password : undefined;
    if (allTrue) {
      const data = {
        id: value.idUser,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        access: selectedAccess,
        password: pwdValue,
      };
      Axios.post("https://cogitest.herokuapp.com/user/update", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(!response.data.emailExist);
        }
      );
      clearCRUD();
    }
  };
  const clearCRUD = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfPassword("");
  };
  const displayAlert = resultMsg === "" ? "none" : "";
  const severityAlert = resultSuccess ? "success" : "error";
  return (
    <div className="crudContainer">
      <Stack sx={{ width: "100%", display: displayAlert }} spacing={2}>
        <Alert severity={severityAlert}>{resultMsg}</Alert>
      </Stack>
      <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
        <TextField
          className="item"
          id="tf outlined-error-helper-text"
          label="Prénom"
          onChange={(e: any) => {
            setFirstName(e.target.value);
          }}
          value={firstName}
          error={errFirstName}
          helperText={helperFirstName}
        />
        <TextField
          className="item"
          id="tf outlined-error-helper-text"
          label="Nom"
          onChange={(e: any) => {
            setLastName(e.target.value);
          }}
          value={lastName}
          error={errLastName}
          helperText={helperLastName}
        />

        <TextField
          className="item"
          id="tf outlined-error-helper-text"
          label="E-mail"
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
          value={email}
          error={errEmail}
          helperText={helperEmail}
        />
        <Password
          className="item"
          label="Mot de passe"
          onChange={(e: any) => {
            setPassword(e.target.value);
          }}
          value={password}
          error={errPassword}
        />
        <Password
          className="item"
          label="Confirmé mot de passe"
          onChange={(e: any) => {
            setConfPassword(e.target.value);
          }}
          value={confPassword}
          error={errConfPassword}
        />
        <Button onClick={updateUser}>Modifier</Button>
      </FormControl>
    </div>
  );
};

export default CrudSettings;
