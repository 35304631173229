import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";

interface Props {
  summary: string;
  details: string;
}
export default function SimpleAccordion({ summary, details }: Props) {
  return (
    <div>
      <Accordion style={{ backgroundColor: "transparent", color: "#023047" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: summary }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ textAlign: "left" }}>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: details }} />{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
