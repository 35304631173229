
import { RISQUES } from "./DATA/TAUX_COTISATION";
import { Classe1Data } from "./DATA/CLASSE1";
import { classe, Classe2Data } from "./DATA/CLASSE2";
import { Classe1AData } from "./DATA/CLASSE1A";
import { VARS } from "./DATA/VARIABLES";
import { Bareme } from "./DATA/BAREME";
import { SSM } from "./DATA/SSM";


export function Calcul(salaireBrute:number,montantModeration:number,classe:Bareme) {
    let salaireSSM:number=5*SSM
    if(salaireBrute<salaireSSM){
        salaireSSM=salaireBrute;
    }
    
    // calcul de cotisations
    var montantAssuranceMaladie:number=salaireSSM*RISQUES.ASSURE.Assurance_maladie_maternité;
    var montantMajoration:number=salaireSSM*RISQUES.ASSURE.Majoration_prestations;
    var montantAssurancePension:number=salaireSSM*RISQUES.ASSURE.Assurance_pension;
    var montantEmployeur=salaireBrute+salaireBrute*(RISQUES.EMPLOYEUR.Assurance_accident+RISQUES.EMPLOYEUR.Assurance_maladie_maternité+RISQUES.EMPLOYEUR.Assurance_pension+RISQUES.EMPLOYEUR.Majoration_prestations+RISQUES.EMPLOYEUR.Mutualité+RISQUES.EMPLOYEUR.Prestations_familiales+RISQUES.EMPLOYEUR.Sante_travail);
    var cotisations:number=montantAssuranceMaladie+montantAssurancePension+montantMajoration;
    var salaireImposable=salaireBrute-cotisations;
    var montantAssuranceDependance:number=(salaireBrute-(SSM*(1/4)))*RISQUES.ASSURE.Assurance_dependance;
    let data:Array<classe>=[];
    let classe_impots:number=0;
    let RevenuInferieur=0;
    if (classe==Bareme.UN) {
        Object.assign(data, Classe1Data);
        classe_impots=VARS.Classe1_Impots;
        RevenuInferieur=VARS.RevenuInferieur_classe1;
    }
    else if (classe==Bareme.UN_A) {
        Object.assign(data, Classe1AData);
        classe_impots=VARS.Classe1A_Impots;
        RevenuInferieur=VARS.RevenuInferieur_classe1A;
    }
    else if (classe==Bareme.DEUX) {
        Object.assign(data, Classe2Data);
        classe_impots=VARS.Classe2_Impots;
        RevenuInferieur=VARS.RevenuInferieur_classe2;
    }
   
    var montantImpots:number=0;

    for (let value of data) {

        if ((salaireImposable>=value.min) && (salaireImposable<=value.max)){

            montantImpots=salaireImposable*value.R-value.I;
            if(salaireImposable<RevenuInferieur){
                montantImpots*=1.07;
            }else{
                montantImpots+=(montantImpots*0.09-classe_impots);
            }
            break;
        }
      }
     
      cotisations+=montantAssuranceDependance;
      var salaireNet:number=salaireBrute-cotisations-montantImpots-montantModeration;
    return [salaireBrute,salaireNet,cotisations,montantImpots,montantModeration,montantEmployeur];
}