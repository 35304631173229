  export const PIBRows = [
    {country: "Luxembourg", usd: "117 846"} ,
    {country: "Belgique", usd: "53 089"} ,
    {country: "Allemagne", usd: "54 844"} ,
    {country: "France", usd: "46 991"} ,
  ];

  export const GD_SMIC_rows=[
      {gd :"Salaire social minimum", db:"(1)  2 313,38 EUR",duree:"173 heures / mois"},
      {gd :"Coût total employeur", db:"(2)  2 608.80 EUR",duree:"173 heures / mois"},
      {gd :"Net employé avant impôts", db:"2 025.36 EUR",duree:"173 heures / mois"},
      {gd :"Ratio coût total / net employé", db:"1.2880",duree:""},
    ]
    export const FR_SMIC_rows=[
        {gd :"SMIC", db:"1 646.00 EUR",duree:"151,67 heures / mois"},
        {gd :"Coût total employeur", db:"1 716.00 EUR",duree:"151,67 heures / mois"},
        {gd :"Net employé avant impôts", db:"1 281.00 EUR",duree:"151,67 heures / mois"},
        {gd :"Ratio coût total / net employé", db:"1,3396",duree:""},
      ]
    