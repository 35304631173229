import { Bareme } from "./BAREME";

export class VARS{
    static RevenuInferieur_classe1=12585;
    static RevenuInferieur_classe1A=12585;
    static RevenuInferieur_classe2=25085;
    static DefaultModeration=214.50;
    //Impots
    static Classe1_Impots=81.01;
    static Classe1A_Impots=79.832;
    static Classe2_Impots=162.022;
}