import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Link } from "react-router-dom";
import vars from "../../styles/variables.module.scss";
import "./Competivite.scss";
import { FR_SMIC_rows, GD_SMIC_rows, PIBRows } from "./data";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: vars.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Competitivite = () => {
  return (
    <>
      <div className="container container_hero">
        <div className="definition fullW">
          <h1>Compétitivité salariale</h1>

          <p>
            Le Grand-Duché de Luxembourg est statistiquement le pays le plus
            riche du monde.
          </p>
          <p>
            Selon le Fonds Monétaire International (FMI), le Produit Intérieur
            Brut (PIB) en Parité de Pouvoir d'Achat (PPA) par habitant pour{" "}
            <span className="orange">2020 </span>
            était de :
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 400,
                margin: "10px auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 400,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Pays</StyledTableCell>
                    <StyledTableCell align="center">USD</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PIBRows.map((row) => (
                    <StyledTableRow key={row.country}>
                      <StyledTableCell component="th" scope="row">
                        {row.country}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.usd}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="center">
              Le PIB PPA par habitant est un indicateur de richesse et de niveau
              de vie. Le Luxembourg a donc un niveau de vie réel de plus du
              double de ses proches voisins.
            </p>
          </p>
          <div>
            <p>
              <h5 className="question">
                Ceci implique-t-il que le Grand-Duché de Luxembourg soit
                socialement moins compétitif que ses voisins ?
              </h5>
              Comparons au 01/01/2014 deux entreprises, l'une française l'autre
              luxembourgeoise, qui souhaiteraient toutes deux embaucher un
              salarié au minimum légal, puis ces mêmes entreprises qui
              souhaiteraient embaucher un informaticien et un comptable de 5 ans
              d'expérience.
            </p>
            <p>
              Parlons tout d'abord du salaire minimum au Grand-Duché et en
              France, ramené au nombre d'heures travaillées :
            </p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Grand-Duché de Luxembourg</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {GD_SMIC_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="center">
              (1) Incluant une cotisation Mutualité classe 1 de 0,46% à la
              charge de l'employeur. <p className="center">(2) Au 01/04/2022</p>
            </p>

            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "10px auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>France</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FR_SMIC_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p></p>
            <p>
              Si nous parlons de salaire minimum légal, le coût total employeur
              par heure effectivement travaillée est de{" "}
              <span className="orange">13.37 EUR</span> pour le Luxembourg
              contre <span className="orange">10.85 EUR</span> pour la France.
              Nous constatons un <span className="underline">surcoût</span> pour
              l'employeur luxembourgeois de{" "}
              <span className="orange">23.23 %</span> .
            </p>
            <p>
              Le salarié touche quant à lui{" "}
              <span className="orange">11.70 EUR /h</span> au Luxembourg contre
              <span className="orange"> 8.44 EUR /h</span> en France, soit un 
              <span className="underline">gain</span>  pour le salarié
              luxembourgeois de <span className="orange">+38.63 %</span>. Ceci
              explique largement le nombre de travailleurs frontaliers au
              Grand-duché de Luxembourg.
            </p>
            <p>
              Faites vos propres simulations en utilisant notre{" "}
              <Link className="a" to="/simulateur">
                simulateur de salaire
              </Link>{" "}
              en ligne.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Competitivite;
