export const questions =[
    {
    summary: "<b>Quand est-ce qu'une domiciliation est interdite ?</b>",
    details: "<p>Toute société ayant une activité économique réelle (signature de contrats, émission de factures à des tiers) doit obligatoirement demander une autorisation d'établissement.</p> <p>Toute société disposant d'une autorisation d'établissement a l'obligation de disposer de locaux adaptés à la nature de son activité. Donc domiciliation interdite.</p>"
},
{
    summary: "<b>Quels sont les services liés à une domiciliation ?</b>",
    details: "<p>De la définition légale, il faut retenir qu'une domiciliation simple (c'est-à-dire sans services associés) est interdite. <p>Les services accessoires liés à une domiciliation en nos bureaux comprennent :</p><ul class='accordionList'><li >Les services comptables</li><li>Les services fiscaux liés auxdits services comptables</li></ul> <p>Les missions sociales (établissement de fiches de salaires, déclarations sociales, etc.) sont optionnelles.</p></p>",
},
{
    summary:"<b>Quels coûts pour une domiciliation ?</b>",
    details:"Au 01/01/2022, la domiciliation vous sera facturée <span class='orange'>2 520 EUR</span> H.T. par an (+TVA à <span class='orange'>17 %</span>), à comparer au coût d'une location simple d'un bureau sur Luxembourg ville (+- 500 EUR par mois + charges locatives). Les prestations comptables et fiscales associées à la domiciliation sont facturées en fonction de l'activité réelle de la société, avec un minimum de <span class='orange'>2 140</span> EUR H.T. par an pour les deux premières années civiles et <span class='orange'>3 745 EUR</span> H.T. par an ensuite."
},    
{
    summary:"<b>Quelles sociétés peuvent-être domiciliées chez nous ?</b>",
    details:"<p>Nous domicilions exclusivement des sociétés anonymes (S.A.) et des sociétés à responsabilité limitée (SARL) de droit luxembourgeois, dont l'objet social est légal.</p><p>Nous portons enfin une attention particulière à la prévention du blanchiment d'argent et du financement du terrorisme, conformément aux normes de notre profession.</p>"
}, 
{
    summary:"<b>Re-domiciliation de votre société</b>",
    details:"<p>N'hésitez pas à nous contacter si vous souhaitez reconsidérer vos conditions de présence au Grand-Duché via notre <a href='/contact'><span class='orange'>formulaire de contact</span></a></p>"
}, 
]