import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "./Contact.scss";
const imgContact = require("../../assets/images/contact.png");
type FormState = {
  email: string;
  name: string;
  object: string;
  message: string;
};

type ServiceMessage = {
  class: string;
  text: string;
};
function Contact() {
  const formId = "YL2hBsB1";
  const formSparkUrl = `https://submit-form.com/${formId}`;

  const initialFormState = {
    email: "",
    name: "",
    object: "",
    message: "",
  };
  const recaptchaKey = "6LdNEo0fAAAAALB_Pq6y6mquEtJlWTj6peKgiWXK";
  const recaptchaRef = useRef<any>();

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    if (validate(formState) === "") {
      setSubmitting(true);
      await postSubmission();
      setSubmitting(false);
    }
  };

  const validate = (values: FormState) => {
    const regexAlphabet = new RegExp("^[A-Za-z\\s-]+$");
    const regexEmail = new RegExp(
      '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@(([^<>()[\\]\\.,;:\\s@"]+\\.)+[^<>()[\\]\\.,;:\\s@"]{2,})$'
    );
    const verifyName = regexAlphabet.test(values.name) && values.name;
    const verifyEmail = regexEmail.test(values.email) && values.email;
    const verifyObject = values.object;
    const verifyMessage = values.message;
    var error: string = "";
    if (!verifyName) {
      error = "Vérifiez votre nom";
    } else if (!verifyEmail) {
      error = "Vérifiez votre e-mail";
    } else if (!verifyObject) {
      error = "Vous devez saisir l'object";
    } else if (!verifyMessage) {
      error = "Vous devez saisir votre message";
    }
    return error;
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken,
    };

    try {
      await axios.post(formSparkUrl, payload);
      setMessage({
        class: "bg-success",
        text: "Merci, nous vous contacterons prochainement.",
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      setMessage({
        class: "bg-danger",
        text: "Désolé, une erreur s'est produite lors du traitement de votre message.",
      });
    }
  };

  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  return (
    <Container className="container">
      <Row className=" mt-3">
        {message && (
          <div className={`my-4 text-white w-full p-4 ${message.class}`}>
            {message.text}
          </div>
        )}
      </Row>

      <Row className="sec_sp">
        <Col lg="5" className="mb-5 illustration">
          <img src={imgContact} className="img-fluid" alt="illustration" />
        </Col>
        <Col lg="7" className="d-flex align-center">
          <form onSubmit={submitForm} className="contact__form w-100">
            <Row>
              <Col lg="8">
                <h1 className="display-4 mb-4 contactus">Contactez-nous</h1>
              </Col>
              <Col lg="6" className="form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nom"
                  type="text"
                  onChange={updateFormControl}
                  value={formState?.name}
                  required
                />
              </Col>
              <Col lg="6" className="form-group">
                <input
                  className="form-control rounded-0"
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  onChange={updateFormControl}
                  value={formState?.email}
                  required
                />
              </Col>
            </Row>

            <input
              className="form-control rounded-0"
              id="object"
              name="object"
              placeholder="Objet"
              type="text"
              onChange={updateFormControl}
              value={formState?.object}
              required
            />
            <textarea
              className="form-control rounded-0"
              id="message"
              name="message"
              placeholder="Message"
              rows={5}
              onChange={updateFormControl}
              value={formState?.message}
              required
            ></textarea>
            <br />

            <Row className="submit_btn">
              <Col lg="12" className="form-group">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={updateRecaptchaToken}
                  className="recaptcha"
                />
              </Col>
              <Col lg="12" className="form-group">
                <button
                  className="btn ac_btn"
                  type="submit"
                  disabled={validate(formState) != "" || submitting}
                  style={{ width: "100%" }}
                >
                  {submitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
