import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import ill_simulateur from "../../assets/images/illustrations/ill_simulateur.png";
import PieChart from "../../components/PieChart/PieChart";
import { Bareme, Situation, Status } from "./DATA/BAREME";
import { SSM } from "./DATA/SSM";
import { VARS } from "./DATA/VARIABLES";
import { Calcul } from "./ScriptSimulateur";
import "./simulateur.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Simulateur = () => {
  const [clickedButton, setClickedButton] = useState(false);
  const [status, setStatus] = useState(Status.Celibataire);
  const [situation, setSituation] = useState(Situation.Sans_enfants);
  const [dataBrut, setDataBrut] = useState("");
  const [dataModeration, setDataModeration] = useState("");
  const [result, setResult] = useState<number[]>([]);

  const updateClasse = () => {
    const classe1A =
      (status == Status.Celibataire || status == Status.Divorce_P3) &&
      situation == Situation.Avec_enfants;

    const classe1 =
      (status == Status.Celibataire || status == Status.Divorce_P3) &&
      situation == Situation.Sans_enfants;

    const classe = classe1 ? Bareme.UN : classe1A ? Bareme.UN_A : Bareme.DEUX;
    return classe;
  };

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const classe = updateClasse();
    if (Number(dataBrut) < Number(dataModeration)) {
      alert("Votre salaire doit être supérieur au taux de modération");
    } else if (SSM > Number(dataBrut)) {
      alert("Votre salaire doit être supérieur ou égâle à " + SSM);
    } else {
      changeResult(classe);
      setClickedButton(true);
    }
  };
  function changeResult(classe: any) {
    setResult(
      Calcul(
        Number(dataBrut),
        dataModeration != "" ? Number(dataModeration) : VARS.DefaultModeration,
        classe
      )
    );
  }
  function changeBrut(val: any) {
    let value = val.target.value;
    if ((!isNaN(Number(value)) || value === "") && Number(value) <= 9999999)
      setDataBrut(value);
  }
  function changeModeration(val: any) {
    let value = val.target.value;
    if ((!isNaN(Number(value)) || value === "") && Number(value) <= 9999999)
      setDataModeration(value);
  }
  function changeStatus(event: React.ChangeEvent<HTMLInputElement>) {
    setStatus(Number((event.target as HTMLInputElement).value));
  }
  function changeSituation(event: React.ChangeEvent<HTMLInputElement>) {
    setSituation(Number((event.target as HTMLInputElement).value));
  }
  return (
    <>
      <div className="container container_hero">
        <div className="definition sim">
          <h1>Simulateur de salaire</h1>
          <p>
            Ce simulateur de salaire utilise les paramètres fiscaux et sociaux
            réels au jour où vous réalisez votre simulation. Il a cependant été
            volontairement limité dans ses fonctionnalités :
            <ul className="listSimulateur">
              <li>
                Le simulateur est basé sur un temps complet (
                <span className="orange">173h/mois</span>)
              </li>
              <li>Les avantages en nature ne sont pas pris en compte</li>
              <li>Les heures supplémentaires ne sont pas prises en compte</li>
              <li>
                La cotisation Mutualité (à charge de l'employeur) fait référence
                à la classe 1 (absentéisme financier{" "}
                <span className="orange">0,75 %</span>)
              </li>
            </ul>
          </p>
        </div>
        <div className="illus">
          <img className="img-fluid" src={ill_simulateur} alt="illustration" />
        </div>
      </div>
      <div className="container InputSimulateur">
        <div className="SituationFamiliale">
          <h3>Situation Familiale</h3>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              <h4>Statut</h4>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={status}
              onChange={changeStatus}
            >
              <FormControlLabel
                control={<Radio />}
                defaultChecked
                value={Status.Celibataire}
                label="Célibataire"
              />
              <FormControlLabel
                value={Status.Marie}
                control={<Radio />}
                label="Marié"
              />
              <FormControlLabel
                value={Status.Divorce_M3}
                control={<Radio />}
                label="Divorcé depuis - de 3 ans"
              />
              <FormControlLabel
                value={Status.Divorce_P3}
                control={<Radio />}
                label="Divorcé depuis + de 3 ans"
              />
            </RadioGroup>
          </FormControl>

          <div className="situation">
            <FormControl>
              <FormLabel id="situationGroup">
                <h4>Situation</h4>
              </FormLabel>
              <RadioGroup
                aria-labelledby="situationGroup"
                name="situationGroup"
                value={situation}
                onChange={changeSituation}
              >
                <FormControlLabel
                  defaultChecked
                  value={Situation.Sans_enfants}
                  control={<Radio />}
                  label="Sans enfant"
                />
                <FormControlLabel
                  control={<Radio />}
                  value={Situation.Avec_enfants}
                  label="Avec enfant(s)"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <h3>Données</h3>
          <div className="Donnees">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingSalaire"
                placeholder="0"
                value={dataBrut}
                onChange={changeBrut}
              />
              <label className="floatingSalaire">Salaire mensuel brut</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingModeration"
                placeholder="0"
                value={dataModeration}
                onChange={changeModeration}
              />
              <label htmlFor="floatingModeration">Modération d'impôts</label>
            </div>
            <button className="calculate" onClick={buttonHandler}>
              Calculer
            </button>
          </div>
        </div>
        {clickedButton ? (
          <div className="chart">
            <div className="text-center">
              Montant employeur:{" "}
              <span className="text-success">{result[5] | 0} €</span>{" "}
            </div>
            <p></p>
            <PieChart
              net={result[1] | 0}
              brut={result[0]}
              moderation={result[4] | 0}
              cotisation={result[2] | 0}
              impots={result[3] | 0}
            />
          </div>
        ) : null}
      </div>
      <div className="container">
        <div className="center">
          La modération d'impôts est une donnée fiscale réduisant la base
          taxable. Si vous êtes travailleur frontalier, le montant à renseigner
          par défaut est de <span className="orange">214,50 €</span> par mois
          couvrant les frais de transports encourus pour un salarié entre son
          domicile et notre siège social.
        </div>
      </div>
    </>
  );
};

export default Simulateur;
