import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ICrudProps } from "../../../interfaces/ICrud";
import ComboBox from "../../MU_Select/MU_Select";
import Password from "../Password/Password";
import "./CrudUsers.scss";

const CrudUsers = ({ label, value }: ICrudProps) => {
  const [selectedAccess, setSelectedAccess] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  //
  const [errFirstName, setErrFirstName] = useState(false);
  const [helperFirstName, setHelperFirstName] = useState("");
  //
  const [errLastName, setErrLastName] = useState(false);
  const [helperLastName, setHelperLastName] = useState("");
  //
  const [errEmail, setErrEmail] = useState(false);
  const [helperEmail, setHelperEmail] = useState("");
  //
  const [errPassword, setErrPassword] = useState(false);
  const [helperPassword, setHelperPassword] = useState("");
  //
  const [errConfPassword, setErrConfPassword] = useState(false);
  const [helperConfPassword, setHelperConfPassword] = useState("");
  //
  const [resultSuccess, setResultSuccess] = useState(false);
  const [resultMsg, setResultMsg] = useState("");
  const data = [
    { label: "Admin", value: 1 },
    { label: "Utilisateur", value: 0 },
  ];
  const clearCRUD = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfPassword("");
  };
  //
  const verifyData = () => {
    const regexAlphabet = new RegExp("^[A-Za-zÀ-ÿ\\s-]+$");
    const regexEmail = new RegExp(
      '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@(([^<>()[\\]\\.,;:\\s@"]+\\.)+[^<>()[\\]\\.,;:\\s@"]{2,})$'
    );
    const regexPassword = new RegExp(
      "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))"
    );
    const verifyFirstName: boolean = regexAlphabet.test(firstName);
    const verifyLastName = regexAlphabet.test(lastName);
    const verifyEmail = regexEmail.test(email);

    if (!verifyFirstName) {
      setErrFirstName(true);
      setHelperFirstName("Le prénom doit être alphanumérique.");
    } else {
      setErrFirstName(false);
      setHelperFirstName("");
    }
    if (!verifyLastName) {
      setErrLastName(true);
      setHelperLastName("Le nom doit être alphanumérique.");
    } else {
      setErrLastName(false);
      setHelperLastName("");
    }
    if (!verifyEmail) {
      setErrEmail(true);
      setHelperEmail("Vérifiez la format de l'e-mail.");
    } else {
      setErrEmail(false);
      setHelperEmail("");
    }
    var verifyPassword = regexPassword.test(password);
    var verifyConfPassword = password === confPassword;
    if (toAdd) {
      if (!verifyPassword) {
        setErrPassword(true);
        alert(
          "Mot de passe : 6 caractères minimum, au moins une lettre majuscule, au moins une lettre minuscule et un chiffre."
        );
      } else {
        setErrPassword(false);
        setHelperPassword("");
        if (!verifyConfPassword) {
          setErrConfPassword(true);
          alert(
            "Le champ de confirmation de mot de passe n'est pas compatible."
          );
        } else {
          setErrConfPassword(false);
          setHelperConfPassword("");
        }
      }
    } else {
      verifyPassword = true;
      verifyConfPassword = true;
    }

    const allTrue =
      verifyFirstName &&
      verifyLastName &&
      verifyEmail &&
      verifyPassword &&
      verifyConfPassword;
    return allTrue;
  };
  const toAdd: boolean = label === "Ajouter";
  const addUser = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
        access: selectedAccess,
      };
      Axios.post("https://cogitest.herokuapp.com/user/add", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(!response.data.emailExist);
        }
      );
      clearCRUD();
    }
  };
  const displayAlert = resultMsg === "" ? "none" : "";
  const severityAlert = resultSuccess ? "success" : "error";
  const [firstRender, setFirstRender] = useState(false);
  function findUser(id: any) {
    Axios.get(`https://cogitest.herokuapp.com/user/${id}`).then(
      (response: any) => {
        if (response.data.found) {
          const user = response.data.user;
          setFirstName(user.firstNameUser);
          setLastName(user.lastNameUser);
          setEmail(user.mailUser);
          setSelectedAccess(user.accessAdmin);
        } else {
          alert(response.data.message);
        }
      }
    );
  }
  const updateUser = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        id: value,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        access: selectedAccess,
      };
      Axios.post("https://cogitest.herokuapp.com/user/update", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(!response.data.emailExist);
        }
      );
      clearCRUD();
    }
  };
  useEffect(() => {
    if (!firstRender && !toAdd) {
      findUser(value);
      setFirstRender(true);
    }
  }, [firstRender]);
  return (
    <div className="crudContainer">
      <Stack sx={{ width: "100%", display: displayAlert }} spacing={2}>
        <Alert severity={severityAlert}>{resultMsg}</Alert>
      </Stack>
      <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
        <TextField
          autoComplete="off"
          className="item"
          id="tf outlined-error-helper-text"
          label="Prénom"
          onChange={(e: any) => {
            setFirstName(e.target.value);
          }}
          value={firstName}
          error={errFirstName}
          helperText={helperFirstName}
        />
        <TextField
          autoComplete="off"
          className="item"
          id="tf outlined-error-helper-text"
          label="Nom"
          onChange={(e: any) => {
            setLastName(e.target.value);
          }}
          value={lastName}
          error={errLastName}
          helperText={helperLastName}
        />

        <TextField
          autoComplete="off"
          className="item"
          id="tf outlined-error-helper-text"
          label="E-mail"
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
          value={email}
          error={errEmail}
          helperText={helperEmail}
        />

        <Password
          className={toAdd ? "item" : "dn"}
          label="Mot de passe"
          onChange={(e: any) => {
            setPassword(e.target.value);
          }}
          value={password}
          error={errPassword}
        />

        <Password
          className={toAdd ? "item" : "dn"}
          label="Confirmé mot de passe"
          onChange={(e: any) => {
            setConfPassword(e.target.value);
          }}
          value={confPassword}
          error={errConfPassword}
        />
        <ComboBox
          fullW={true}
          className="combo"
          placeholder="Droits"
          data={data}
          onChangeValue={(e: any) => {
            setSelectedAccess(e.target.value);
          }}
          value={String(selectedAccess)}
        />
        <Button onClick={toAdd ? addUser : updateUser}>{label}</Button>
      </FormControl>
    </div>
  );
};

export default CrudUsers;
