import { Button, FormLabel } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Datatables from "../../../components/AdminPanel/Datatable/Datatables";
import Navbar from "../../../components/AdminPanel/NavBar/Navbar";
import Sidebar from "../../../components/AdminPanel/SideBar/Sidebar";
import BasicDatePicker from "../../../components/AdminPanel/timeRangePicker";
import ComboBox from "../../../components/MU_Select/MU_Select";
import { IItem } from "../../../interfaces/IItem";

const columns: GridColDef[] = [
  { field: "lastNameUser", headerName: "Utilisateur", flex: 2 },
  { field: "contentTask", headerName: "Tâche", flex: 2 },
  { field: "nameClient", headerName: "Client", flex: 2 },
  { field: "year", headerName: "Exercice", flex: 1 },
  { field: "duration", headerName: "Qtes", flex: 1 },
  { field: "date", headerName: "Date", flex: 2 },
];
const Observe = () => {
  const now = new Date();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedTask, setSelectedTask] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedFirstDate, setSelectedFirstDate] = useState(
    now.toISOString().slice(0, 10)
  );
  const [selectedLastDate, setSelectedLastDate] = useState(
    now.toISOString().slice(0, 10)
  );
  const [firstRender, setFirstRendre] = useState(true);
  const [userDataRows, setUserRows] = useState<IItem[]>([]);
  const [clientDataRows, setClientRows] = useState<IItem[]>([]);
  const [taskDataRows, setTaskRows] = useState<IItem[]>([]);
  const [observeRows, setObserveRows] = useState<any[]>([]);
  const [sumDurations, setSumDurations] = useState(0);

  const loadDataCombo = () => {
    Axios.get("https://cogitest.herokuapp.com/client/list").then(
      (response: any) => {
        var rows: Array<IItem> = [
          {
            label: "Tout",
            value: 0,
          },
        ];
        response.data.forEach(function (client: any) {
          rows.push({
            label: client.nameClient,
            value: client.id,
          });
        });
        setClientRows(rows);
      }
    );
    Axios.get("https://cogitest.herokuapp.com/user/list").then(
      (response: any) => {
        var rows: Array<IItem> = [
          {
            label: "Tout",
            value: 0,
          },
        ];
        response.data.forEach(function (user: any) {
          rows.push({
            label: user.firstNameUser + " " + user.lastNameUser,
            value: user.id,
          });
        });
        setUserRows(rows);
        Axios.get("https://cogitest.herokuapp.com/task/list").then(
          (response: any) => {
            var rows: Array<IItem> = [
              {
                label: "Tout",
                value: 0,
              },
            ];
            response.data.forEach(function (task: any) {
              rows.push({
                label: task.contentTask,
                value: task.id,
              });
            });
            setTaskRows(rows);
          }
        );
      }
    );
  };
  const submitReview = async () => {
    var date1 = new Date(selectedFirstDate);
    var date2 = new Date(selectedLastDate);

    if (date1 > date2) {
      alert("Date début doit être > à la date de fin");
      return;
    }
    var day1 = date1.getDate();
    var month1 = date1.getMonth() + 1;
    var year1 = date1.getFullYear();
    var day2 = date2.getDate();
    var month2 = date2.getMonth() + 1;
    var year2 = date2.getFullYear();
    var sDate1 = year1 + "-" + month1 + "-" + day1;
    var sDate2 = year2 + "-" + month2 + "-" + day2;

    await Axios.get(
      `https://cogitest.herokuapp.com/observe/getData/${selectedClient}&${selectedUser}&${sDate1}&${sDate2}&${selectedTask}`
    ).then((response: any) => {
      var rows: Array<any> = [];
      var i = 1;
      var sum = 0;
      response.data.forEach(function (observe: any) {
        observe["id"] = i;
        var dateResponse = new Date(observe["date"]);
        observe["date"] =
          dateResponse.getDate() +
          "/" +
          (dateResponse.getMonth() + 1) +
          "/" +
          dateResponse.getFullYear();

        sum += Number(observe.duration);
        rows.push(observe);
        i++;
      });
      setSumDurations(sum);
      setObserveRows(rows);
    });
  };
  useEffect(() => {
    if (firstRender) {
      loadDataCombo();
      setFirstRendre(false);
    }
  });

  return (
    <>
      <Navbar />
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <div className="listContainer">
            <h4>Saisie d'un intervalle:</h4>
            <div className="items">
              <div className="item">
                <BasicDatePicker
                  label="Date début"
                  value={selectedFirstDate}
                  onChange={(value: any) => {
                    setSelectedFirstDate(value);
                  }}
                />
              </div>
              <div className="item">
                <BasicDatePicker
                  label="Date fin"
                  value={selectedLastDate}
                  onChange={(value: any) => {
                    setSelectedLastDate(value);
                  }}
                />
              </div>
              <div className="item">
                <ComboBox
                  placeholder="Utilisateur"
                  onChangeValue={(e: any) => {
                    setSelectedUser(e.target.value);
                  }}
                  data={userDataRows}
                  value={selectedUser}
                />
              </div>
              <div className="item">
                <ComboBox
                  placeholder="Tâche"
                  onChangeValue={(e: any) => {
                    setSelectedTask(e.target.value);
                  }}
                  data={taskDataRows}
                  value={selectedTask}
                />
              </div>
              <div className="item">
                <ComboBox
                  placeholder="Client"
                  onChangeValue={(e: any) => {
                    setSelectedClient(e.target.value);
                  }}
                  data={clientDataRows}
                  value={selectedClient}
                />
              </div>
            </div>
            <div className="text-center">
              <Button onClick={submitReview} variant="contained">
                Cherche
              </Button>
            </div>
            <hr />
            <div className="text-center">
              <div className="row mb-3">
                <div className="col-md-4 text-justify">
                  <FormLabel>
                    Total quantités: {sumDurations} heure(s)
                  </FormLabel>
                </div>
              </div>
              <Datatables
                rows={observeRows}
                columns={columns}
                label={"Observe"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Observe;
