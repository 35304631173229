// barème 2017
export const Classe1AData=[
    {min:0,max: 1960 , R:0 ,I:0},
    {min:1965,max: 2065, R: 0.120 , I: 233.50000},
    {min:2070, max: 2170,R:  0.135 , I: 266.49750},
    {min:2175,max:  2270,R:  0.150 , I: 299.05500},
    {min:2275,max:  2375,R:  0.165 , I: 333.17250},
    {min:2380,max:  2480,R:  0.180 , I: 368.85000},
    {min:2485,max:  2590,R:  0.210 , I: 443.32500},
    {min:2595,max:  2695,R:  0.240 , I: 521.04000},
    {min:2700,max:  2905,R:  0.270 , I: 601.99500},
    {min:2790, max: 2910,R:  0.300 , I: 686.19000},
    {min:2915, max: 3020,R:  0.330 , I: 773.62500},
    {min:3025, max: 3130,R:  0.360 , I: 864.30000},
    {min:3135,max:  8415,R:  0.390 , I: 958.21500},
    {min:8420,max:  12585,R:  0.400 , I: 1042.40000},
    {min:12590, max: 16750,R:  0.410 , I: 1168.25000},
    {min:16755,max:  9999999.99,R:  0.420 , I: 1335.77000},
]

