import React from "react";
import { Link } from "react-router-dom";
import illustration from "../../assets/images/illustrations/ill_const.png";
import ill_question from "../../assets/images/illustrations/ill_question.png";
import SimpleAccordion from "../../components/Accordion/SimpleAccordion";
import "./Constitution.scss";
import { questions } from "./questions";
import Tabs from "./Tabs";

const Constitution = () => {
  return (
    <>
      <div className="container container_hero">
        <div className="definition">
          <h1>Constitution de société</h1>
          <p>
            Le Grand-Duché de Luxembourg est une plateforme idéale pour mener
            des activités commerciales ou de services transfrontalières.
            <p>
              Veuillez consulter notre{" "}
              <Link to="/faq">
                <span className="orange"> F.A.Q </span>
              </Link>
              pour plus de renseignements.
            </p>
          </p>
        </div>
        <div className="illus">
          <img src={illustration} alt="illustration" className="img-fluid" />
        </div>
      </div>
      <div className="container">
        <div className="definition">
          <h2>
            Nous pouvons vous assister à toutes les étapes de votre projet :
          </h2>
          <p className="listEtapes">
            <ul className="listEtapes">
              <li>Analyse et documentation de votre business case</li>
              <li>
                Détermination du statut le plus approprié à l'activité envisagée
              </li>
              <li>
                Rédaction des statuts en lien avec un notaire de la place (acte
                authentique obligatoire) sauf pour une SARL-S (voir la{" "}
                <Link to="/faq">
                  <span className="orange"> F.A.Q</span>
                </Link>
                )
              </li>
              <li>
                Introduction du dossier en demande d'autorisation
                d'établissement auprès du Ministère de l'économie
              </li>
              <li>
                Mise en relation avec les banques de la place pour l'ouverture
                d'un compte bancaire
              </li>
              <li>
                Introduction du dossier auprès des différentes administrations
                luxembourgeoises pour l'obtention d'un numéro de TVA
                intracommunautaire et d'un matricule employeur
              </li>
              <li>
                Mise en contact éventuelle avec des administrateurs indépendants
                et/ou un commissaire aux comptes indépendant
              </li>
              <li>
                Mise en contact éventuelle avec des prestataires de services
                tiers (standard téléphonique, bureaux loués en temps partagé,
                local de stockage, hosting de systèmes informatiques, etc.)
              </li>
              <li>
                Recrutement de personnel (comptables, informaticiens, etc.)
              </li>
              ...
            </ul>
            Pour toute question, n'hésitez pas à nous contacter via notre
            <Link className="link" to="/contact">
              {" "}
              formulaire de contact.
            </Link>
          </p>
        </div>
      </div>
      <div className="container">
        <h2>Quel type de société pour quels besoins ?</h2>
        <div className="article">
          <div className="contenu container_hero">
            <div className="definition fullW">
              <p>
                Nous analyserons ci-après deux formes de sociétés commerciales,
                à savoir la société anonyme (S.A.) et la société à
                responsabilité limitée (SARL). Les sociétés civiles et les
                autres formes de sociétés de capitaux sont rares et pratiquement
                sans intérêt dans le cadre d'une activité transfrontalière.
                <p></p>
                <h4>Quelques questions à se poser :</h4>
                {questions.map((item, i) => (
                  <SimpleAccordion
                    summary={item.summary}
                    details={item.details}
                  />
                ))}
              </p>
            </div>
          </div>
          <div className="illus">
            <img
              style={{
                width: "800px",
                marginTop: "25%",
              }}
              src={ill_question}
              alt="illustration"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container  container_hero">
        <div className="definition fullW">
          <h2>Comparaison entre une S.A. et une SARL</h2>
          <p>
            Les données reproduites ci-après ne prétendent pas être exhaustives.
            Elles sont publiées afin de vous donner une idée aussi précise que
            possible des principales caractéristiques desdites sociétés.
          </p>
          <Tabs />
        </div>
      </div>
    </>
  );
};

export default Constitution;
