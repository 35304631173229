export const questions =[
    {
    summary: "<b>Ai-je besoin d'un capital important ?</b>",
    details: "Si la réponse est non, choisissez une S.A. car vous avez la possibilité de ne libérer que le quart du capital social (soit <span class='orange'>7 500 EUR</span>)"
},
{
    summary: "<b>Ai-je besoin de discrétion ?</b>",
    details: "<p>Si tel est le cas, seule une S.A. pourra répondre à votre besoin car l'identité des actionnaires n'est pas publique. Attention : les identités des associés fondateurs et des administrateurs sont publiées au Mémorial (équivalent du Journal Officiel) et au Registre du Commerce luxembourgeois</p> <p>L’identité des bénéficiaires effectifs détenant plus de <span class='orange'>25%</span> des droits est publiée au Registre des Bénéficiaires Effectifs, même si la détention est indirecte</p>",
},
{
    summary:"<b>Ai-je besoin d'une structure légère (associé unique) ou non (pluralité d'associés) ?</b>",
    details:"Si vous êtes seul, alors optez pour la SARL unipersonnelle. Les contraintes administratives sont réduites au minimum"
}    

]